
import FileSaver from 'file-saver'
import axios from 'axios'
export default class fileSaver {
  /**
     * 导出Excel文件
     * @param {*} res   文件流
     * @param {*} name  文件名
     */
  static excel (res, name) {
    const blob = new Blob([res], {
      type: 'application/vnd.ms-excel'
    })
    FileSaver.saveAs(blob, name + '.xlsx')
  }

  /**
     * 导出CSV文件
     * @param {*} res   文件流
     * @param {*} name  文件名
     */
  static csv (res, name) {
    const blob = new Blob([res], {
      type: 'application/vnd.ms-excel'
    })
    FileSaver.saveAs(blob, name + '.csv')
  }

  /**
     * 导出图片1
     * @param {*} url 图片地址
     * @param {*} name  文件名
     */
  static imgURLs (url, name) {
    const last = url.substring(url.lastIndexOf('.'), url.length)
    FileSaver.saveAs(url, `${name}${last}`)
  }

  /**
     * 导出图片2
     * @param {*} res 文件流
     * @param {*} name  文件名
     */
  static downLoadImg (res, filename) {
    const blob = new Blob([res], {
      type: 'image/jpeg'
    })
    FileSaver.saveAs(blob, `${filename}.jpg`)
  }

  /**
     * 导出JSON文件
     * @param {*} res   文件流
     * @param {*} name  文件名
     */
  static Json (res, name) {
    const blob = new Blob([JSON.stringify(res)], {
      type: 'text/plain;charset=utf-8'
    })
    FileSaver.saveAs(blob, name + '.json')
  }

  /**
     * 读取保存的JSON文件
     * @param {*} name  文件名
     */
  static getJson (name) {
    return axios.get(`/db/${name}.json`).then(res => res.data).catch(() => null)
  }
}
