<template>
  <div class='main'>1</div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
    }
  },
  computed: {},
  methods: {},
  created () { }
}
</script>
<style lang='scss' scoped>
.main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
</style>
