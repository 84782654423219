<template>
  <el-container class="container-wrap">
    <el-header>
      <div class="icon">
        <img src="@/assets/icon.png" />
        <span v-text="'J-Note'" />
      </div>
      <div class="move"></div>
      <div class="win">
        <i
          class="el-icon-minus"
          @click.stop="oprationWin('min')"
        />
        <i
          class="el-icon-full-screen"
          @click.stop="oprationWin('max')"
        />
        <i
          class="el-icon-close"
          @click.stop="oprationWin('close')"
        />
      </div>
    </el-header>
    <el-container class="wrap">
      <el-aside width="235px">
        <el-input
          v-model="search"
          placeholder="查找对应文件"
          size="small"
          clearable
        />
        <span
          v-for="(v,i) in fileList"
          v-text="v.value"
          :key="v+i"
          @click="fileChoice(v.value)"
          :class="{'active':active===v.value}"
        />
      </el-aside>
      <el-container>
        <el-main class="main">
          <el-select
            v-model="select"
            placeholder="请选择文件，默认为今日新文件"
            clearable
            filterable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <div
            class="main-info"
            v-for="(v,i) in data.demo"
            :key="v+i"
          >
            <el-input
              v-model="v[`demo_${i+1}_title`]"
              :placeholder="`标题${i+1}`"
              size="normal"
            />
            <div
              v-for="(res,index) in v[`demo_${i+1}_info`]"
              :key="res+index"
            >
              <el-input
                class="mt20"
                v-model="res.info"
                type="textarea"
                :autosize="{ minRows: 3, maxRows: 10}"
                :placeholder="`条目${index+1}`"
                size="normal"
              />
              <div v-if="v[`demo_${i+1}_info`].length===index+1">
                <el-button
                  type="primary"
                  size="default"
                  @click="addRow(v[`demo_${i+1}_info`],index+2)"
                  v-text="'+'"
                />
                <el-button
                  type="primary"
                  size="default"
                  v-if="v[`demo_${i+1}_info`].length!==1"
                  @click="deleteRow(v[`demo_${i+1}_info`],index)"
                  v-text="'-'"
                />
              </div>
            </div>
            <div v-if="data.demo.length===i+1">
              <el-button
                type="warning"
                size="default"
                @click="addRow(data.demo,i+2,true)"
                v-text="'+'"
              />
              <el-button
                type="success"
                size="default"
                v-if="data.demo.length!==1"
                @click="deleteRow(data.demo,i)"
                v-text="'-'"
              />
            </div>
          </div>
          <detail v-if="showDetail" />
        </el-main>
      </el-container>
    </el-container>
    <el-footer>
      <div class="main-opration">
        <span
          @click="save"
          class="core-button"
          v-text="'save'"
        />
        <el-button
          type="primary"
          size="default"
          @click="show"
        >show</el-button>
      </div>
    </el-footer>
  </el-container>
</template>
<script>
import detail from './detail'
import { ipcRenderer } from 'electron'

export default {
  components: {
    detail
  },
  data () {
    return {
      data: {},
      initialize: {
        demo: [{
          demo_1_title: '一、',
          demo_1_status: '',
          demo_1_info: [{
            info: '1. ',
            status: ''
          }]
        }]
      },
      today: this.$dayjs().format('YYYY-MM-DD'),
      showDetail: false,
      select: '',
      options: null,
      search: '',
      active: null
    }
  },
  watch: {
    select: {
      async handler (val) {
        if (val) {
          this.data = await this.$fileSaver.getJson(val)
        } else {
          this.data = await this.$fileSaver.getJson(this.today) || this.initialize
          this.data.date = this.today
        }
        console.log(this.data)
      },
      immediate: true
    }
  },
  computed: {
    fileList () {
      console.log(this.options)
      return this.$filterData(this.options, this.search, 'value')
    }
  },
  methods: {
    oprationWin (type) {
      ipcRenderer.send(type)
    },
    async save () {
      this.$fileSaver.Json(this.data, this.select || this.today)
    },
    async show () {
      const files = require.context('../../public/db', false, /.json$/).keys()
      this.options = files.map(v => { return { value: v.replace(/\.json/gi, '').replace(/\.\//gi, ''), label: v.replace(/\.json/gi, '').replace(/\.\//gi, '') } })
      console.log(this.options)
    },
    addRow (item, index, flag) {
      const obj = {}
      if (flag) {
        obj[`demo_${index}_title`] = this.numChange(index) + '、'
        obj[`demo_${index}_status`] = ''
        obj[`demo_${index}_info`] = [{ info: '1. ', status: '' }]
      } else {
        obj.info = index + '. '
        obj.status = ''
      }
      item.push(obj)
    },
    deleteRow (item, i) {
      if (i >= 0) {
        item.splice(i, 1)
      } else {
        item.pop()
      }
    },
    async fileChoice (val) {
      this.active = val
      this.data = await this.$fileSaver.getJson(val)
      console.log(this.active)
    },
    numChange (val) {
      const num = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九']
      const unit = ['', '十', '百', '千', '万', '亿', '万亿', '亿亿']
      let strIns = ''
      let result = ''
      let unitPos = 0
      let zero = true
      while (val > 0) {
        const v = val % 10
        // console.log(unitPos, 'unitPos')
        // console.log(val, 'val')
        // console.log(v, 'v')
        // console.log(zero, '----------------zero----------------')
        if (v === 0) {
          if (!zero) {
            zero = true
            result = num[v] + result
            // console.log(num[v], 'v === 0 !zero  num[v]')
            // console.log(result, 'v === 0 !zero  result')
          }
        } else {
          zero = false
          strIns = num[v]
          // console.log(num[v], 'v !== 0 num[v]')
          strIns += unit[unitPos]
          // console.log(strIns, 'v !== 0 strIns')
          result = strIns + result
          // console.log(result, 'v !== 0 result')
        }
        unitPos++
        val = Math.floor(val / 10)
      }
      return result
    }

  },
  created () {
    this.show()
  }
}
</script>
  <style lang='scss' scoped>
@font-face {
  font-family: english;
  src: url("../style/font/Mitr-Light.ttf");
}
.container-wrap {
  box-shadow: 0 0 10px #000;
  border: 1px solid rgba(0, 0, 0, 0.35);
  border-radius: 3px;
}
.wrap {
  flex: 1;
  overflow: auto;
}
.main {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  &-info {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  &-opration {
    margin: 5px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
.el-main {
  padding: 10px 20px;
}
.el-header {
  height: 40px !important;
  display: flex;
  border-radius: 3px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid aliceblue;
  margin-bottom: 5px;
  .icon {
    display: flex;
    align-items: center;
    img {
      width: 20px;
      height: 20px;
      margin-right: 15px;
    }
    span {
      font-family: english;
      font-size: 16px;
      color: #000;
    }
  }
  .move {
    flex: 1;
    -webkit-app-region: drag;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .win {
    font-size: 16px;
    color: lightskyblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 80px;
    i {
      cursor: pointer;
      &:hover {
        color: rgb(7, 22, 245);
      }
    }
  }
}
.el-aside {
  overflow: auto;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 15px;
    padding: 10px;
    border: 1px solid blue;
    border-radius: 5px;
    cursor: pointer;
    color: #000;
    &:hover {
      background: rgba(27, 40, 219, 0.1);
      color: rgba(7, 22, 245);
    }
  }
  .active {
    background: rgba(27, 40, 219, 0.1);
    color: rgba(7, 22, 245);
  }
}
.el-footer {
  border-radius: 3px;
}
.el-container {
  width: 100%;
  height: 100%;
}
.el-input {
  margin-bottom: 5px;
}
::-webkit-scrollbar {
  display: none;
}
</style>
