import DataBase from '../database/index'
import axios from 'axios'
import fileSaver from './fileSave'
import filterData from './filterData'
import dayjs from 'dayjs'

const db = new DataBase()

// const fileSaver = (data, name) => {
//   const content = JSON.stringify(data)
//   const blob = new Blob([content], { type: 'text/plain;charset=utf-8' })
//   this.$fileSaver.saveAs(blob, name)
// }

export default {
  install (Vue) {
    Vue.prototype.$dataBase = db
    Vue.prototype.$axios = axios
    Vue.prototype.$dayjs = dayjs
    Vue.prototype.$fileSaver = fileSaver
    Vue.prototype.$filterData = filterData
  }
}
