<template>
  <div class="container-wrap">
    <home />
  </div>
</template>

<script>
import home from './views/Home'
export default {
  name: 'app',
  components: {
    home
  }
}
</script>

<style lang="scss">
@import "./style/base";
</style>
