
class DataBase {
  create (dbName, dbVersion) {
    if ('indexedDB' in window) {
      const openRequest = window.indexedDB.open(dbName, dbVersion)
      console.log(openRequest)
    } else {
      console.log('can not use db ! ! !')
    }
  }
}

export default DataBase
